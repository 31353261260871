import $ from 'jquery';
import Log from 'laravel-mix/src/Log';
import ScrollMagic from 'scrollmagic';

// init controller
const controller = new ScrollMagic.Controller();

/* *** *** *** ***
** 1幕目
*** *** *** *** */

/*
    0~5000
    シーンをピン留め
    */
new ScrollMagic.Scene({
    duration: 5000,
    offset: 0
})
.setPin('#firstview')
.addTo(controller);

/*
    0~200
    タイトル1をフェードイン
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 0
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#title01").css({
        opacity: 1 * p
    });
})
.addTo(controller);

/*
    0~1000
    背景1をズーム
    背景1を上にスクロール
*/
new ScrollMagic.Scene({
    duration: 1000,
    offset: 0
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview01Bg").css({
        transform: "scale(" + Number(1 + p / 5) + ") translateY(" + Number(-1 * p * 50) + "px)"
    });
})
.addTo(controller);

/*
    500~800
    タイトル1をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 300,
    offset: 500
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#title01").css({
        opacity: 1 - p
    });
})
.addTo(controller);

/*
    800~1000
    背景1をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 800
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview01").css({
        opacity: 1 - p,
    });
})
.addTo(controller);




/* *** *** *** ***
** 2幕目
*** *** *** *** */

/*
    1000~1200
    タイトル2をフェードイン
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 1000
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#title02").css({
        opacity: 1 * p
    });
})
.addTo(controller);

/*
    1000~2000
    背景2をズーム
    背景2を上にスクロール
*/
new ScrollMagic.Scene({
    duration: 1000,
    offset: 900
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview02Bg").css({
        transform: "scale(" + Number(1 + p / 5) + ") translateY(" + Number(-1 * p * 50) + "px)"
    });
})
.addTo(controller);

/*
    1500~1800
    タイトル2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 300,
    offset: 1500
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#title02").css({
        opacity: 1 - p
    });
})
.addTo(controller);

/*
    1800~2000
    背景2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 1800
})
.on("progress", function (prog) {
    const p = prog.progress;
    $("#firstview02").css({
        opacity: 1 - p,
    });
})
.addTo(controller);





/* *** *** *** ***
** 3幕目
*** *** *** *** */

/*
    2000~2200
    タイトル2をフェードイン
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 2000
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#title03").css({
            opacity: 1 * p
        });
    })
    .addTo(controller);

/*
    2000~3000
    背景2をズーム
    背景2を上にスクロール
*/
new ScrollMagic.Scene({
    duration: 1000,
    offset: 1900
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#firstview03Bg").css({
            transform: "scale(" + Number(1 + p / 5) + ") translateY(" + Number(-1 * p * 50) + "px)"
        });
    })
    .addTo(controller);

/*
    2500~2800
    タイトル2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 300,
    offset: 2500
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#title03").css({
            opacity: 1 - p
        });
    })
    .addTo(controller);

/*
    2800~3000
    背景2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 2800
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#firstview03").css({
            opacity: 1 - p,
        });
    })
    .addTo(controller);
    
    
    
    

/* *** *** *** ***
** 4幕目
*** *** *** *** */

/*
    3000~3200
    タイトル2をフェードイン
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 3000
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#title04").css({
            opacity: 1 * p
        });
    })
    .addTo(controller);

/*
    3000~4000
    背景2をズーム
    背景2を上にスクロール
*/
new ScrollMagic.Scene({
    duration: 1000,
    offset: 2900
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#firstview04Bg").css({
            transform: "scale(" + Number(1 + p / 5) + ") translateY(" + Number(-1 * p * 50) + "px)"
        });
    })
    .addTo(controller);

/*
    3500~3800
    タイトル2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 300,
    offset: 3500
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#title04").css({
            opacity: 1 - p
        });
    })
    .addTo(controller);

/*
    3800~4000
    背景2をフェードアウト
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 3800
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#firstview04").css({
            opacity: 1 - p,
        });
    })
    .addTo(controller);
    
    
    
    
    
/* *** *** *** ***
** 5幕目
*** *** *** *** */

/*
    4000~4200
    タイトル2をフェードイン
*/
new ScrollMagic.Scene({
    duration: 200,
    offset: 4000
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#title05").css({
            opacity: 1 * p
        });
    })
    .addTo(controller);

/*
    4000~5000
    背景2をズーム
    背景2を上にスクロール
*/
new ScrollMagic.Scene({
    duration: 1000,
    offset: 3900
})
    .on("progress", function (prog) {
        const p = prog.progress;
        $("#firstview05Bg").css({
            transform: "scale(" + Number(1 + p / 5) + ") translateY(" + Number(-1 * p * 50) + "px)"
        });
    })
    .addTo(controller);

